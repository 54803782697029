* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section {
  background-image: url("./background.jpg");
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  /* min-height: 100vh; */
  padding: 50px;
  font-family: Arial, Helvetica, sans-serif;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: animateBg 5s linear infinite;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* @keyframes animateBg {
  100% {
    filter: hue-rotate(360deg);
  }
} */

.container {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 400px;
  position: relative;
  border: solid 2px grey;
  background: transparent;
  border-radius: 20px;
  backdrop-filter: blur(15px);
}

h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-align: center;
  color: white;
  font-size: 2em;
}

.input-box {
  position: relative;
  width: 310px;
  margin: 30px 0;
  border-bottom: 2px solid white;
}

.input-box label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: white;
  transform: translateY(-50%);
  font-size: 1em;
  pointer-events: none;
  transition: 0.5s;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label {
  top: 5px;
}

.input-box input {
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  color: white;
  font-size: 1em;
  padding-right: 35px;
  padding-left: 5px;
  background: transparent;
}

.input-box .icon {
  position: absolute;
  top: 35%;
  right: 5px;
  color: white;
}

.remember-forget {
  color: white;
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  margin-top: -15px;
  margin-bottom: 15px;
}

.remember-forget a {
  text-decoration: none;
  color: white;
}

.remember-forget a:hover {
  text-decoration: underline;
}

.btn input {
  width: 100%;
  height: 40px;
  font-size: 1.2em;
  border: none;
  outline: none;
  border: solid whitesmoke 1px;
  color: whitesmoke;
  border-radius: 20px;
  cursor: pointer;
}

.btn input:hover {
  color: black;
  background-color: aliceblue;
}

.btn input:active {
  background-color: transparent;
  color: whitesmoke;
}

.no-account {
  margin-top: 25px;
  text-align: center;
  color: white;
}

.no-account a {
  font-weight: 600;
  text-decoration: none;
  color: white;
}

.no-account a:hover {
  text-decoration: underline;
}

@media (max-width: 360px) {
  .container {
    width: 100%;
    height: 100vh;
    border: none;
    border-radius: 0;
  }
  .input-box {
    width: 290px;
  }
}
