.background {
  background: rgb(255, 255, 225);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 225, 1) 32%,
    rgba(239, 255, 222, 1) 100%,
    rgba(85, 182, 184, 1) 100%
  );
}

.bg {
  background: rgb(254, 255, 165);
  background: linear-gradient(
    90deg,
    rgba(254, 255, 165, 1) 32%,
    rgba(212, 255, 167, 1) 100%,
    rgba(85, 182, 184, 1) 100%
  );
}
